import { FastModal } from "@core/atoms/FastModal"
import { useAuthContext } from "@core/context/auth"
import { FmkComboBox } from "@core/formik/FmkComboBox"
import { FmkTimePicker } from '@core/formik/FmkTimePicker'
import type { DialogAwaitLogic, DialogLogic } from "@core/hooks/useDialog"
import { useValidationSchema } from "@core/hooks/useValidationSchema"
import { current_real_lessons_query, match_history_query, match_infos_list_query, user_info_query } from "@core/queries"
import { openapi } from "@core/services/openapi"
import { EuropeParis, getShowedMonthDt } from "@core/utils"
import { FmkUtils } from "@core/utils/formik"
import { FontAwesome5 } from "@expo/vector-icons"
import { SubmitButton } from "@native-base/formik-ui"
import { Formik } from "formik"
import { capitalize, min } from "lodash"
import { DateTime, Duration } from "luxon"
import { Box, Button, Heading, Icon, Modal, Text } from "native-base"
import { useMemo } from "react"
import { useErrorBoundary } from "react-error-boundary"

const durationOptions = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]
    .map((hour) => hour * 60)
    .map((minute) => ({
        label: Duration.fromObject({ minutes: minute }).toFormat("h'h'mm").replace("0h30", "30min").replace("h00", "h"),
        value: minute,
    }))


export class FormValues {
    @FmkUtils.DefField day: number | null
    @FmkUtils.DefField startTimeMin: number | null
    @FmkUtils.DefField durationMin: number | null
}

const FIELDS = FmkUtils.getFields(FormValues)

export interface AddLessonModalProps {
    dialogLogic: DialogAwaitLogic
    matchId: number
}

export function AddLessonModal(props: AddLessonModalProps) {
    const { dialogLogic, matchId } = props

    const schema = useValidationSchema(
        (Yup) =>
            Yup.object().shape({
                [FIELDS.day]: Yup.number().nullable().required("Requis").min(1, "Requis"),
                [FIELDS.startTimeMin]: Yup.number().nullable().required("Requis").min(1, "Requis"),
                [FIELDS.durationMin]: Yup.number().nullable().required("Requis").min(0.5, "Requis"),
            }),
        []
    )

    const auth = useAuthContext()
    const teacherId = auth.userId

    const { matchInfosListQuery } = match_infos_list_query.use({ teacherId })
    const { currentRealLessonsQuery } = current_real_lessons_query.use({ teacherId })
    const { userInfoQuery } = user_info_query.use({ teacherId: auth.userId })
    const monthDt = getShowedMonthDt()
    const monthStr = monthDt.toISODate()
    const matchInfo = matchInfosListQuery.helpers.findById(matchId)
    // const matchLessons = currentRealLessonsQuery.data?.findByMatchId(matchId)

    const { showBoundary } = useErrorBoundary()

    const currentDay = DateTime.now().day.valueOf()

    return (
        <FastModal isOpen={dialogLogic.isOpen}>
            <Formik<FormValues>
                initialValues={{
                    durationMin: null,
                    day: DateTime.now().day,
                    startTimeMin: null
                }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await auth.ensureValidToken()
                        const newDbLesson = await openapi.teachersApp.submitFastLesson({
                            startedAtOfficial: monthDt.set({ day: values.day!, hour: Math.floor(values.startTimeMin! / 60), minute: values.startTimeMin! % 60 }).toJSDate(),
                            durationMin: values.durationMin!,
                            matchId,
                            teacherId: teacherId!,
                        })

                        //refetch base query
                        await currentRealLessonsQuery.refetch()

                        //invalidate other impacted queries
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_history_query.getKey({ matchId }))
                        currentRealLessonsQuery.queryClient.invalidateQueries(match_infos_list_query.getKey({ teacherId }))

                        dialogLogic.hide("success")
                    } catch (err) {
                        showBoundary(err)
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                <Modal.Content width="350px">
                    {/* <Modal.CloseButton /> */}
                    <Modal.Header>
                        <Heading size="md">Déclaration cours</Heading>
                    </Modal.Header>
                    <Modal.Body>
                        <Text fontSize="md">
                            {matchInfo?.Program?.Learner?.firstName} {matchInfo?.Program?.Learner.lastName}
                        </Text>
                        <Text color="primary.500">{matchInfo?.label}</Text>


                        <Text>{capitalize(DateTime.now().toFormat("EEEE dd MMMM"))}</Text>

                        <FmkTimePicker _formControl={{ mt: "3" }} name={FIELDS.startTimeMin} label="Heure de début" modalHeader="Heure de début" clearable />

                        <FmkComboBox _formControl={{ mt: "3" }} name={FIELDS.durationMin} label="Durée" modalHeader="Durée" clearable options={durationOptions} configure={(opt) => opt} />

                        <Box mt={2}>
                            <Text>
                                <Icon as={FontAwesome5} name="exclamation-triangle" color="warning.500" mr={1} />
                                Saisie définitive après un délai de 12h.
                            </Text>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant="subtle2" colorScheme="trueGray" onPress={() => dialogLogic.hide("cancel")}>
                                Annuler
                            </Button>

                            <SubmitButton colorScheme="pedagome">Enregistrer</SubmitButton>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Formik>
        </FastModal>
    )
}
